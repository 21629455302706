import React,{ useState , useEffect } from 'react'
import { Wrapper,Content,Content1 } from './header.style'
import { Link } from 'react-router-dom';

import SideNav from '../SideNav'
import CloseIcon from '@mui/icons-material/Close';
import { Dropdown , ButtonToolbar} from 'rsuite';

const Header = () => {

    return (
        <>
        <Wrapper>
            <div className='logo' >
            <Link to='/'>
                <img src='/images/logo.png' alt='logo'/>
            </Link>
            </div>
            <Content>

                <Dropdown title="Transportation" trigger="hover">
                  <Dropdown.Item as={Link} to="/pickup">pickup service</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/">rent a car</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/">Limousine with driver </Dropdown.Item>
                </Dropdown>

                <Dropdown title="Sleeping" trigger="hover">
                  <Dropdown.Item as={Link} to="/">hotels</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/">apartments</Dropdown.Item>
                </Dropdown>

                <Dropdown title="Healthcare Services" trigger="hover">
                  <Dropdown.Item as={Link} to="/">Dental Klinik</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/">Beauty Klinik</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/">cosmetic manicure and pedicure</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/">spa and hamam</Dropdown.Item>
                </Dropdown>

                <Dropdown title="Tourguide Service" trigger="hover">
                  <Dropdown.Item as={Link} to="/">sightseeing</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/">shopping</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/">gourmet restaurant</Dropdown.Item>
                </Dropdown>


            </Content>

        </Wrapper>
        </>
    )
}

export default Header
