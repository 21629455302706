import React from "react";

import { Wrapper,Content } from "./pickup.style";

import Section from "../Section";
import Specs from "../Gen_Spec";
import Header from "../Header";

const Pickup = () =>{

    document.title = "Pickup Service";
    return(
        <Wrapper>
            <Header/>
            <Content>
                <Section
                title="Pickup Service"
                desc=""
                backgroundImg="pickup.png"
                arrow="true"
                />
            </Content>
            <Content>
                <Specs/>
            </Content>
        </Wrapper>
    )
}

export default Pickup;
