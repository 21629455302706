import React,{ useEffect } from "react";
import AOS from 'aos'
import "aos/dist/aos.css";

import { Wrapper,Content } from "./spec.style";

const Specs = () =>{

    useEffect(() => {
        AOS.init({duration: 1400});
        AOS.refresh();
    }, []);


    return(
        <Wrapper>
            <Content>
                <div className="row" >
                    <div className="col" data-aos='fade-right'>
                        <img src="/images/pickup1.png" alt="game"/>
                    </div>
                    <div className="col text" data-aos='fade-left'>
                        <h3> minimize costs </h3>
                        <p> 	Halte Verbrauch und Kosten immer im Blick
	Spare mit flexiblen Stromtarifen

	Lade Dein Elektroauto zum günstigsten Preis
	Minimiere die Kosten Deiner Wärmepumpe

	Profitiere von vielen weiteren Vergünstigungen	</p>
                    </div>
                </div>
                <div className="row" >
                    <div className="col text1" data-aos='fade-right'>
                        <h3> maximize returns </h3>
                        <p> 	Maximiere den Ertrag Deiner PV Anlage
	Maximiere den Ertrag Deines Speichers
	Maximiere den Ertrag Deiner Wallbox	 </p>
                    </div>
                    <div className="col" data-aos='fade-left'>
                        <img src="/images/pickup2.png" alt="connected"/>
                    </div>
                </div>
                <div className="row" >
                    <div className="col" data-aos='fade-right'>
                        <img src="/images/pickup3.png"/>
                    </div>
                    <div className="col text" data-aos='fade-left'>
                        <h3> simplify your life </h3>
                        <p> 	Keine Überraschungen mehr.
	Du hast immer alles im Blick
	Wir erledigen die Bürokratie.	 </p>
                    </div>
                </div>

            </Content>
        </Wrapper>
    )
}

export default Specs;
