import React from 'react'
import { Wrapper,Content } from './home.style'

import Section from './Section'
import Header from './Header';

function Home() {

    document.title = "Countrol";
    return (
        <>
        <Header/>
        <Wrapper>
            <Content>
                <Section title="Transportation" desc="" leftbtn="Book Now" rightbtn="More Information" backgroundImg="main.png" dynamicRoute="#" />
            </Content>
            <Content>
                <Section title="Sleeping" desc="" leftbtn="Book Now" rightbtn="More Information" backgroundImg="sleep.png" dynamicRoute=""/>
            </Content>

            <Content>
                <Section title="Healthcare Services" desc="" leftbtn="Book Now" rightbtn="More Information" backgroundImg="Healthcare.png" dynamicRoute=""/>
            </Content>

            <Content>
                <Section title="Tourguide Service " desc="" leftbtn="Book Now" rightbtn="More Information" backgroundImg="Tourguide.png" dynamicRoute=""/>
            </Content>

        </Wrapper>
        </>
    )
}

export default Home
