import React from 'react'
import { Wrapper,ContentTop,ContentMid,Content } from './section.style'
import {useNavigate} from 'react-router-dom';

function Section({ title,desc,backgroundImg,link,leftbtn,rightbtn,arrow,range,speed,hp,top,dynamicRoute }) {

    
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${dynamicRoute}`);
  };
    
    return (
        <Wrapper bg={backgroundImg} >
            <ContentTop>
                <h1>{title}</h1>
                <p>{desc} <a href='#'>{link}</a></p>
            </ContentTop>

            <div>
                <ContentMid className={arrow ? '' : 'buttons'} >
                    {leftbtn &&
                    <div className='left'>
                        <button>{leftbtn}</button>
                    </div>
                    }
                    {rightbtn && 
                        <div className='right'>
                            <button onClick={handleClick}>{rightbtn}</button>
                        </div>
                    }
                </ContentMid>
                {range && 
                <Content>
                    <div className="Info-bar">
                        <div className="Specs" >
                            <h2>{range} kWh</h2>
                            <p>minimize costs</p>
                        </div>
                        <div className="Specs" >
                            <h2>{speed} kWh</h2>
                            <p>maximize returns</p>
                        </div>
                        <div className="Specs" >
                            <h2>{top} kWh</h2>
                            <p>simplify your life</p>
                        </div>

                        <div className="order_btn">
                            <button> Jetzt bestellen </button>
                        </div>
                    </div>
                </Content>
                }
                {arrow && 
                    <div className='arrow'>
                        <img src='/images/down-arrow.svg' alt='arrow'/>
                    </div>
                }
            </div>
        </Wrapper>
    )
}

export default Section
